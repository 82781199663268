.blogImage{
 background-image: url('../../Assets/blog.jpg');
 height: 100vh;
 width: auto;
 background-size: cover;
}
.blogImage>h3{
  font-size: 30px ;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}
.mainBlog{
    width: 100%;
    
}
.topTitle{
    padding-top: 20px;
}
@media (320px <= width <= 800px )  {
    .blogImage{
        height: 40vh;
    }
  }