.virtual-main-container {
    background: url("../Assets/goaImges/b939d13d-b31c-4546-ac0f-afb498a26538.jpeg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.videoCardContainer {
    padding: 50px 40px;
    /* background: rgba(0, 0, 0, 0.603); */
}

.videoCardContainer h2,
.videoCardContainer p {
    color: white;
}

.videoCardContainer h2 {
    font-size: 28px;
    margin-bottom: 15px;
    margin-top: 30px;
}
.videoCardContainer p {
    font-size: 16px;
    margin: 10px;
}
.yotube_video_btn a {
    width: 70px;
    height: 70px;
}
.yotube_video_btn a svg {
    width: inherit;
    height: inherit;
    object-fit: contain;
    cursor: pointer;
    /* border-radius: 50%; */
}

.yotube_video_btn a img:hover {
    outline: 2px solid red;
    outline-offset: 10px;
    border: 1px solid rgb(255, 255, 255);
}

@media (max-width: 565px) {
    .videoCardContainer {
        padding: 24px;
    }
    .yotube_video_btn a img {
        width: 80px;
        height: 80px;
    }
    .videoCardContainer h2 {
        margin-top: 24px;
        font-size: 26px;
    }
    .videoCardContainer p {
        font-size: 18px;
    }
}
