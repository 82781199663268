.App {
    text-align: center;
    background: white;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.ant-modal-body {
    width: 100%;
}

.App-link {
    color: var(--dark-green);
}

:where(.css-dev-only-do-not-override-1qfezbu).ant-modal-root .ant-modal-mask {
    background-color: rgb(0 0 0 / 78%);
}

.textfloat-button.ant-float-btn-default .ant-float-btn-body {
    background-color: #396214 !important;
    box-shadow: none;
    width: 100%;
}
.textfloat-button.ant-float-btn-default .ant-float-btn-body .ant-float-btn-content .ant-float-btn-description {
    font-weight: 700 !important;
    color: rgb(255 255 255) !important;
}
.ant-float-btn-default .ant-float-btn-body {
    background-color: #ffffff00;
}

.ant-float-btn-default .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon,
.ant-float-btn {
    color: rgb(255, 255, 255);
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-modal-close {
    display: none !important;
}
