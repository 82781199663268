.form-main-container {
    padding: 50px 40px;
    background: url("../Assets/goaImges/GOA-beach.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.formCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.contactus-form-container form {
    margin-top: 30px;
}

.formCardContainer h2,
.formContainer h2 {
    font-size: 24px;
    color: var(--dark-green);
    font-weight: 600;
    letter-spacing: 0.17px;
}
.formContainer h2 {
    margin: 0 0 20px 0;
    text-align: center;
}
.formContainer {
    width: 500px;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
    background: #ffffff;
    backdrop-filter: blur(10px);
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.699);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
}

.formContainer form label {
    color: black;
    font-weight: 500;
}

.formContainer .form-item input:focus,
.formContainer .form-item input:focus-within {
    border-color: var(--green);
    background-color: #ffffffb3;
}

.formContainer form :where(.css-dev-only-do-not-override-1qfezbu).ant-input-filled {
    background-color: rgb(238 238 238 / 73%);
}
.formBtnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 24px;
    row-gap: 10px;
}

.formBtnBox p {
    width: 150px;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    border: 1px solid var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.formBtnBox p.cancelBtn {
    background: #ffffffdf;
}

.formBtnBox p.cancelBtn a,
.formBtnBox p.cancelBtn a:hover,
.formBtnBox p.cancelBtn a:before {
    color: var(--green) !important;
}
.formBtnBox p:hover {
    border: 1px solid var(--green);
}

.formBtnBox p a {
    color: #ffffff;
}
.formBtnBox p a:hover {
    color: #ffffff !important;
    background-color: transparent;
    text-shadow: nthree;
}
.formBtnBox p a:hover:before {
    left: 0%;
    right: auto;
    width: 100%;
}
.formBtnBox p a:before {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 0px;
    z-index: -1;
    content: "";
    color: #ffffff !important;
    background: var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

/* /form modal css */

.contactForm-modal .ant-modal-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    box-shadow: none;
    
}
.ant-modal-content{
    width: 100% !important;
}

.ant-modal .ant-modal-body{
    width: 100% !important;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIconContainer {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    font-size: 24px;
    color: gray;
    z-index: 100;
}
@media (max-width: 565px) {
    .formContainer {
        width: 100%;
        padding: 40px 30px;
    }
    .contactForm-modal .ant-modal-content {
        align-items: normal;
    }
    .form-main-container {
        padding: 40px 24px;
    }
    .closeIconContainer {
        top: 15px;
        right: 15px;
    }
}
