.mainNavContainer {
    /* height: 100vh;
    background: url("../Assets/goaImges/Goa-beach3.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover; */
}

header {
    width: 100%;
    background-color: #ffffff94;
    text-align: center;
    position: relative;
    position: fixed;
    top: 0;
    overflow: hidden;
    z-index: 99;
    transition: all 0.5s ease;
    backdrop-filter: blur(10px);
    font-size: 14px;
    -webkit-backdrop-filter: blur(10px);
}

.pageScrolled header {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.left_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 30px;
    width: -webkit-fill-available;
}

header .header_navbar_container {
    padding: 16px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 14px;
    /* gap: 20px; */
    position: relative;
}

.logo_container {
    width: 100px;
    height: 50px;
}

.logo_container img {
    height: 100%;
    object-fit: cover;
}

/* header nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    line-height: 60px;
    overflow-y: auto;
} */

.menu_Item_Container {
    width: calc(100% - 250px);
}

header nav {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(176px, 1fr));
    gap: 5px;
    text-align: left; */
    display: flex;
    overflow-y: auto;
    height: 100%;
    flex-wrap: wrap;
    gap: 10px;
}

.footerLink-Container p,
.header nav p {
    cursor: pointer;
    color: black;
    width: max-content;
}

header nav a,
.footerLink-Container p a {
    font-weight: 500;
    padding: 0px 10px;
    line-height: 1;
    text-decoration: none;
    border-radius: 2px;
    cursor: pointer;
}

header nav a {
    color: #fff;
}

header nav a:hover,
.active.menumenuItem {
    color: var(--green);
    font-weight: 600;
}

.pageScrolled header nav a,
.footerLink-Container p a {
    color: black;
    font-weight: 500;
}

.drawer-menuItem a,
.copyrite-text a:hover {
    color: var(--green);
}

.pageScrolled header nav a:hover {
    color: var(--green);
    font-weight: 600;
}

/* header nav a:hover {
    border: 1px solid var(--green);
    border-radius: 4px;
} */

.footerLink-Container p a:hover {
    color: var(--green);
    font-weight: 700;
}

.Drawer-menu_Container {
    width: 100%;
}

.drawer-menuItem {
    padding: 10px 15px;
    margin-bottom: 3px;
    border-radius: 6px;
}

.drawer-menuItem:hover {
    background: var(--green);
}

.drawer-menuItem:hover a {
    color: #fff;
    font-weight: 600;
}

.navBtnBox {
    width: 200px;
}

.TitalContentContainer {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #23232387;
}

.contentCard {
    /* background: rgb(25 25 25 / 85%); */
    padding: 30px;
    border-radius: 30px;
    color: #fff;
    animation: 1.5s fadeInUp;
    margin-top: 30px;
    /* box-shadow: rgba(192, 192, 192, 0.2) 0px 7px 29px 0px; */
}

.emptyHedader {
    height: 95px;
}

@keyframes fadeInUp {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

.contentCard h1 {
    font-size: 42px;
    line-height: 40px;
    letter-spacing: 1.5px;
    font-weight: 700;
    margin: 0;
}

.contentCard h2 {
    font-size: 30px;
    letter-spacing: 0.6px;
    margin: 0;
    font-weight: 500;
}

.contentCard h3 {
    color: rgb(255, 255, 255);
    letter-spacing: 0.16px;
    font-weight: 400;
    font-size: 22px;
    text-decoration: underline;
}

.jay_shreeram_img {
    width: 200px;
    height: 100px;
}

.jay_shreeram_img img {
    width: inherit;
    height: inherit;
    object-fit: contain;
}

.homeCardTextContent {
    text-align: center;
    color: #fff;
}

.navBtnBox p {
    color: #fff;
    cursor: pointer;
    position: relative;
    border: 1px solid #ffffff;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.pageScrolled .navBtnBox p a
/* a:-webkit-any-link  */ {
    color: var(--green);
    text-decoration: none !important;
}
.pageScrolled .navBtnBox p {
    color: var(--green);
    border: 1px solid var(--green);
}

.navBtnBox p:hover {
    border: 2px solid var(--green);
}

.pageScrolled .navBtnBox p:hover {
    border: 2px solid #ffffff;
}

.navBtnBox p a:hover {
    color: #ffffff !important;
    background-color: transparent;
    text-shadow: nthree;
    text-decoration: none;
}

.navBtnBox p a:hover:before {
    left: 0%;
    right: auto;
    width: 100%;
}

.navBtnBox p a:before {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 0px;
    z-index: -1;
    content: "";
    color: #ffffff !important;
    background: var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

/* footer css */

.footer-main-container {
    padding: 24px 40px;
    background: rgb(245 245 245);
}

.footer-Content-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 16px;
}

.copyrite-text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 22px;
    margin-bottom: 0;
}

.copyrite-text a {
    color: rgb(43, 43, 43);
    text-decoration: none;
}

.footerLink-Container {
    text-align: start;
}

.footer-navBtnBox {
    width: 310px;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    border: 1px solid var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

.footer-navBtnBox p a {
    color: #ffffff;
    text-decoration: none;
}

.footer-navBtnBox:hover p a {
    color: var(--green) !important;
}
.footer-navBtnBox:hover {
    color: var(--green) !important;
    background-color: transparent;
    text-shadow: nthree;
}

.address-text p {
    margin: 0;
    color: black;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: 0.15px;
}

/* banenner image container css */
.hero-banner-container {
    padding: 20px 0;
    animation: 1.5s fadeInUpBanner;
}

@keyframes fadeInUpBanner {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.hero-banner-Image img {
    height: 100%;
    width: 100%;
    /* border-radius: 8px; */
    object-fit: contain;
    max-width: 100%;
}

@media (max-width: 768px) {
    header .header_navbar_container {
        flex-direction: column;
        align-items: flex-start;
    }

    nav {
        gap: 5px;
    }

    .menu_Item_Container {
        width: calc(100% - 100px);
    }

    .emptyHedader {
        height: 160px;
    }
    .pageScrolled .navBtnBox p {
        margin-bottom: 0;
    }
}

@media (max-width: 565px) {
    .menu_Item_Container {
        width: 100%;
    }
    header {
        position: relative;
    }
    .emptyHedader {
        height: 0px;
    }
}

@media (max-width: 565px) {
    header .header_navbar_container {
        padding: 16px 24px;
    }

    .footer-Content-box {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-main-container {
        padding: 24px;
    }

    .pageScrolled .navBtnBox p {
        height: 40px;
        margin-bottom: 0;
    }

    .address-text p {
        margin: 20px 0;
        text-align: start;
    }
}

@media (max-width: 490px) {
    .jay_shreeram_img {
        height: 80px;
    }

    .homeCardTextContent h1 {
        font-size: 24px;
        line-height: 37px;
    }

    .left_header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 14px;
    }
    header .header_navbar_container,
    .footer-main-container {
        padding: 16px;
    }
    .footerLink-Container p {
        margin: 6px 0;
    }
}
