.mainHeader-text-container {
    text-align: center;
}

.mainHeader-text-container h3 {
    font-size: 26px;
    color: rgb(37, 37, 37);
    letter-spacing: 0.16px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.mainHeader-text-container h4 {
    font-size: 20px;
    color: var(--dark-green);
    font-weight: 400;
    letter-spacing: 0.16px;
    text-decoration: underline;
    margin: 10px 0;
}

@media (max-width: 768px) {
    .mainHeader-text-container h3 {
        font-size: 24px;
    }
}

@media (max-width: 565px) {
    .mainHeader-text-container h3 {
        font-size: 22px;
    }
    .mainHeader-text-container h4 {
        font-size: 16px;
    }
    .abhinandan-lodha-page-container {
        padding: 24px;
    }
    .lodha-detail-container {
        margin-top: 0;
    }
    .about-summary-container p {
        font-size: 16px;
    }
    .amenities-Slider-container {
        padding: 24px;
    }
}
@media (max-width: 490px) {
    .abhinandan-lodha-page-container {
        padding: 24px 16px;
    }
    .mainHeader-text-container h3 {
        font-size: 24px;
        margin-top: 10px;
    }
    .mainHeader-text-container h4 {
        font-size: 18px;
    }
}
