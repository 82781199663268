body {
    margin: 0;
    font-family: "Poppins", sans-serif;
}

:root{
    --green:#396214;
    --dark-green:#6FB62E
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-container {
    max-width: 600px;
    margin: 0 auto;
}

.form-item {
    margin-bottom: 20px;
    text-align: start;
}

.form-item label {
    display: block;
    margin-bottom: 12px;
    color: #272727;
    font-weight: 500;
    font-size: 16px;
}

.form-item input {
    width: 95%;
    padding: 10px;
    font-size: 16px;
    border-radius: 7px;
    /* border: 1 px solid gray; */
    border-color: #aeaeae !important;
    border-width: 1px;
    border-style: solid;
}

.submit-btn {
    color: #ffffff !important;
    background: var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
    height: 40px;
    width: 130px;
}

.submit-btn:hover {
    color: #ffffff !important;
    background: var(--green);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.ant-modal-content{
    width: 100% !important;
}

.ant-modal .ant-modal-body{
    width: 100% !important;
}