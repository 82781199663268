.location-page-container {
    background: rgb(245 245 245);
    padding: 30px 40px 50px 40px;
    margin: auto;
}

.Location-detail-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 35px;
}

.location-summary p {
    font-size: 18px;
    font-weight: 500;
    color: rgb(9 9 9);
    letter-spacing: 0.15px;
    line-height: 26px;
}

.map-image-container {
    width: 100%;
    height: 600px;
    object-fit: contain;
    margin-top: 30px;
}
.map-image-container img {
    height: inherit;
    width: inherit;
    object-fit: contain;
}

.location-summary {
    font-size: 18px;
    font-weight: 500;
    color: #54595c;
}
.locationCardContainer {
    width: 80%;
    margin: auto;
    /* padding: 25px 0; */
}
.locationInfoCard {
    margin: 16px;
    height: 240px;
    background: white;
    border-radius: 10px;
    padding: 24px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.locationInfoCard:hover {
    margin-top: 10px;
    transition-delay: 0.2s;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
        rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.locationInfoCard h3 {
    font-size: 17px;
    letter-spacing: 0.15px;
    line-height: 24px;
    color: var(--dark-green);
}
.locationInfoCard p {
    font-size: 16px;
    color: rgb(0 0 0);
    letter-spacing: 0.15px;
    line-height: 26px;
}

@media (max-width: 1252px) {
    .locationCardContainer {
        width: 90%;
    }
    .locationInfoCard {
        height: 270px;
    }
}
@media (max-width: 992px) {
    .locationCardContainer {
        width: 100%;
    }
    .locationInfoCard {
        height: 340px;
    }
}
@media (max-width: 768px) {
    .locationInfoCard {
        height: 300px;
        margin: 8px;
        padding: 16px;
    }
    .map-image-container,
    .map-image-container img {
        height: 240px;
        width: 100%;
    }
}

@media (max-width: 565px) {
    .locationInfoCard {
        height: max-content;
    }
    .MapContainer iframe {
        width: 100% !important;
        height: 300px !important;
    }
    .location-page-container {
        padding: 24px;
    }
    .Location-detail-container {
        margin-top: 0px;
    }
    .location-summary {
        margin: 5px 0;
    }
}

@media (max-width: 492px) {
    .location-page-container {
        padding: 24px 16px;
    }
}
