.pricecard-main-Container {
    background: rgb(245 245 245);
    padding: 30px 40px;
    margin: auto;
}

.blog_post {
    background: #fff;
    width: 250px;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.pricecard-container {
    margin-top: 30px;
}
.blog_post:hover {
    box-shadow: rgba(0, 200, 255, 0.2) 0px 2px 8px 0px;
}
.card_container_copy {
    padding: 40px 24px 20px 24px;
}

.card_container_copy h3 {
    margin: 0 0 12px 0;
    color: var(--green);
    font-size: 1.25rem;
}

.card_container_copy h1 {
    margin: 0 0 1.4rem 0;
    font-size: 2.5rem;
    letter-spacing: 0.5px;
    color: #333;
}

.card_container_copy small {
    font-size: 16px;
    font-weight: 800;
}

.card_container_copy p {
    font-size: 16px;
    line-height: 26px;
    color: #333;
    margin: 0;
}
.pricdbtnbox {
    margin-top: 40px;
}
.btn_primary {
    width: 200px;
    margin: auto;
    outline: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: var(--green);
    border: 1px solid var(--green);
    font-size: 1rem;
    /* transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1); */
    text-decoration: none;
}
.blog_post:hover .btn_primary {
    color: white;
    /* transition-delay: 0.2s; */
    border: 1px solid rgba(255, 255, 255, 0.009);
    background-image: linear-gradient(to top, #009dff, #04c9ff, #0abaff, #11b0ff, #18a6ff);
}

.title-h1 {
    margin-top: 0.5em;
    margin-bottom: 1.4em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: inherit;
    color: #ffffff;
    font-size: 38px;
}

.title-h1 .light {
    font-weight: normal;
}

.pricing-price-wrapper {
    background-color: rgb(255 255 255 / 18%);
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    padding-top: 45px;
    padding-bottom: 34px;
    text-align: center;
    box-shadow: 5px 9px 56px rgba(0, 0, 0, 0.13), 0px -1px 4px rgba(29, 29, 29, 0.08);
}
.pricing-price {
    background-color: var(--green);
    background-color: var(--green);
    -webkit-box-shadow: 0px 0px 0px 10px var(--dark-green);
    -moz-box-shadow: 0px 0px 0px 10px var(--dark-green);
    box-shadow: 0px 0px 0px 10px var(--dark-green);
    color: #fff;
    border-radius: 50%;
    height: 140px;
    width: 200px;
    display: inline-block;
    padding-top: 60px;
}

.pricing-cost {
    font-size: 38px;
    font-weight: 600;
    line-height: 1;
}

.pricing-table-style-4 .time {
    font-size: 24px;
    font-weight: 300;
}

.pricing-row-title {
    background-color: #303241;
    padding: 16px 10px;
    text-align: center;
}

.pricing_row_title {
    color: var(--green);
    line-height: 38px;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.pricing-table.pricing-table-style-4 .pricing-row {
    background-color: #393b4a;
}
.pricing-table-style-4 figure.pricing-row:first-of-type {
    padding-top: 15px;
}
.pricing-table-style-4 .pricing-row {
    padding: 5px 10px;
}
figure {
    margin: 0;
    display: block;
}

figure {
    display: block;
    padding-block-start: 0.4em;
    padding-block-end: 0.4em;
    padding-inline-start: 40px;
    padding-inline-end: 40px;
    background-color: rgb(226 226 226 / 41%);
    text-align: left;
    font-size: 16px;
    line-height: 26px;
}

.pricing-row:before {
    vertical-align: middle;
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    color: var(--green);
    text-align: center;
    padding-right: 10px;
}

.strike:before {
    content: none;
}
.pricing_row_title {
    color: var(--green);
}

/* body {
    color: #5f727f;
} */

.pricing-column {
    float: none;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin-bottom: 42px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 320px;
    margin-top: 20px;
}

.pricing-column-wrapper {
    min-width: 271px;
    text-align: center;
}

.pricing-row-title {
    background-color: rgb(255 255 255 / 49%);
    border-top: 1px solid gainsboro;
}

.margin-body {
    position: relative;
    width: 90%;
    box-sizing: border-box;
    margin: auto;
}

.pricing-row {
    border-top: 1px solid #96969666;
}

.pricing-price-wrapper {
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    padding-top: 45px;
}

.pricing-column-wrapper + .pricing-column-wrapper .pricing-price {
    background-color: #a637ec;
    -webkit-box-shadow: 0px 0px 0px 10px rgba(166, 55, 236, 0.31);
    -moz-box-shadow: 0px 0px 0px 10px rgba(166, 55, 236, 0.31);
    box-shadow: 0px 0px 0px 10px rgba(166, 55, 236, 0.31);
}

.strike {
    text-decoration: line-through;
    color: #99a9b5;
}

.pricing-column-wrapper + .pricing-column-wrapper .pricing_row_title {
    color: #a637ec;
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing_row_title {
    color: #ee417c;
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing-price {
    background-color: #ee417c;
    -webkit-box-shadow: 0px 0px 0px 10px rgba(238, 65, 124, 0.31);
    -moz-box-shadow: 0px 0px 0px 10px rgba(238, 65, 124, 0.31);
    box-shadow: 0px 0px 0px 10px rgba(238, 65, 124, 0.31);
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing_row_title {
    color: #ff994e;
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing-price {
    background-color: #ff994e;
    -webkit-box-shadow: 0px 0px 0px 10px rgba(255, 153, 78, 0.31);
    -moz-box-shadow: 0px 0px 0px 10px rgba(255, 153, 78, 0.31);
    box-shadow: 0px 0px 0px 10px rgba(255, 153, 78, 0.31);
}

.pricing-column-wrapper + .pricing-column-wrapper .pricing-row:before {
    color: #a637ec;
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing-row:before {
    color: #ee417c;
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing-row:before {
    color: #ff994e;
}

.gem-button {
    cursor: pointer;
    border-width: 2px;
    line-height: 36px;
    border-style: solid;
    background: transparent;
    height: 40px;
    line-height: 40px;
    padding: 0 17px;
    font-size: 14px;
    margin: 25px;
    position: relative;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
}
.gem-green {
    border-radius: 0px;
    border-color: var(--dark-green);
    color: var(--green) !important;
}
.gem-green:hover {
    background-color: var(--green);
    color: rgb(255, 255, 255) !important;
    border: none;
}

.gem-purpel {
    border-radius: 0px;
    border-color: rgb(166, 55, 236);
    color: rgb(166, 55, 236) !important;
}

.gem-purpel:hover {
    background-color: #a637ec;
    color: rgb(255, 255, 255) !important;
}

.gem-orange {
    border-radius: 0px;
    border-color: #ee417c;
    color: #ee417c !important;
}

.gem-orange:hover {
    background-color: #ee417c;
    color: rgb(255, 255, 255) !important;
}

.gem-yellow {
    border-radius: 0px;
    border-color: #ff994e;
    color: #ff994e !important;
}
.gem-yellow:hover {
    background-color: #ff994e;
    color: rgb(255, 255, 255) !important;
}

.gem-button-position-center {
    text-align: center;
    display: block;
    background-color: rgb(255 255 255);
    border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid gainsboro;
}

.pricing-column:hover .pricing-price-wrapper {
    box-shadow: 0px 35px 80px rgba(4, 255, 236, 0.25), 0px 0px 0px rgba(10, 197, 173, 0.33);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.pricing-column:hover {
    overflow: hidden;
}

.pricing-column-wrapper + .pricing-column-wrapper .pricing-column:hover .pricing-price-wrapper {
    box-shadow: 0px 35px 80px rgba(230, 2, 241, 0.34), 0px 0px 0px rgba(21, 21, 20, 0.79);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing-column:hover .pricing-price-wrapper {
    box-shadow: 0px 35px 80px rgba(255, 4, 166, 0.25), 0px 0px 0px rgba(222, 6, 84, 0.33);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper + .pricing-column-wrapper .pricing-column:hover .pricing-price-wrapper {
    box-shadow: 0px 35px 80px rgba(241, 139, 2, 0.34), 0px 0px 0px rgba(21, 21, 20, 0.79);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 992px) {
    /* .blog_post {
        width: 190px;
    }
    .card_container_copy {
        padding: 40px 16px 20px 16px;
    } */
    .blog_post {
        width: calc(100% - 20px);
    }
    .pricecard-container {
        margin: 30px 15% 0;
    }

    .btn_primary {
        width: 100%;
    }
    .card_container_copy h1 {
        margin: 0 0 1rem 0;
        font-size: 2rem;
    }
    .pricdbtnbox {
        margin-top: 30px;
    }
    .card_container_copy h3 {
        font-size: 1.2rem;
    }
}

@media (max-width: 768px) {
    /* .blog_post {
        width: 190px;
    }
    .card_container_copy {
        padding: 40px 16px 20px 16px;
    } */
    .blog_post {
        width: calc(100% - 20px);
        margin-bottom: 20px;
    }
    .pricecard-container {
        margin: 0;
    }
}

@media (max-width: 565px) {
    .pricecard-main-Container {
        padding: 24px;
    }
}
@media (max-width: 492px) {
    .pricing-column {
        width: 280px;
    }
}
