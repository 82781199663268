.amenities-Slider-container {
    background: white;
    padding: 30px 0;
}

.sliderContainer {
    margin-top: 35px;
}
.amenities-services-container {
    padding: 30px 40px;
}

.sliderImageContainer {
    height: 250px;
    margin-top: 30px;
    padding: 0 8px;
}

.sliderImageContainer img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
    transition: 1s ease;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.sliderImageContainer:hover img {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    transition: 1s ease;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.CarousalImageContainer {
    width: 100%;
    height: 500px;
}
.CarousalImageContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.swiper-container {
    height: calc(50vh - 120px);
    margin: 60px;
}

.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: linear;
}

.swiper-slide {
    overflow: hidden;
    background-position: center center;
    background-size: cover;
}

.copy {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    letter-spacing: 0.06em;
}
/* a {
    color: white;
  } */

.ServicesCard {
    background: #fafafa;
    margin: auto;
    padding: 24px;
    height: 85%;
    border-radius: 8px;
    border: 1px solid gainsboro;
    letter-spacing: 0.17px;
    border-bottom: 10px solid var(--green);
}

.ServicesCard:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-color: var(--green);
}
.ServicesCard h4 {
    font-size: 20px;
    color: var(--green);
    margin-top: 10px;
    margin-bottom: 15px;
}

.ServicesCard p {
    text-align: start;
    padding-left: 16px;
    font-size: 16px;
    color: rgb(9 9 9);
    letter-spacing: 0.15px;
    line-height: 26px;
    margin-bottom: 5px;
}

.ServicesCard p::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: #00e613;
    border-radius: 10px;
    margin-left: -16px;
    margin-top: 8px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.swiper {
    width: 100%;
    height: 70vh;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 992px) {
    .ServicesCard p {
        margin-bottom: 0px;
    }
    .ServicesCard {
        height: 90%;
    }
}

@media (max-width: 565px) {
    .amenities-services-container {
        padding: 16px 24px;
    }
    .CarousalImageContainer {
        width: 100%;
        height: 300px;
    }

    .amenities-services-container {
        padding: 24px 30px;
    }
    .ServicesCard {
        height: fit-content;
    }
}

@media (max-width: 492px) {
    .ServicesCard h4 {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .amenities-services-container {
        padding: 24px 16px;
    }
    .ServicesCard {
        padding: 20px 10px;
    }
    .ServicesCard p {
        font-size: 14px;
    }
}
