.contactus-main-container {
    padding: 30px 40px 50px 40px;
}

.contact-infoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 30px;
}

.MapContainer {
    width: 55%;
}

.contactus-form-container {
    width: 45%;
}
.contactus-form-container h3 {
    margin-top: 0;
    color: #54595c;
}
.warning-texts {
    color: red;
    margin-bottom: 20px;
}

.more-contact-info {
    text-align: start;
    line-height: 26px;
}

.contactus-form-container input:focus,
.contactus-form-container input:hover {
    border-color: var(--green);
    background-color: #ffffffb3;
}

.contactus-form-container form label {
    color: black;
    font-weight: 500;
}

/* why us css */
.whyUs-container {
    padding: 30px 20% 50px 20%;
    background: rgb(245 245 245);
}
.whyUsCard {
    background: white;
    margin: auto;
    padding: 16px 30px;
    border-radius: 24px;
    border: 1px solid gainsboro;
    letter-spacing: 0.17px;
    text-align: start;
}

.whyUsCard:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-color: var(--green);
}
.whyUsCard h4 {
    font-size: 18px;
    color: var(--green);
    margin: 0px 0;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--green);
}

.whyUsCard p {
    font-size: 16px;
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .contact-infoContainer {
        flex-direction: column;
    }
    .whyUs-container {
        padding: 30px 10% 50px 10%;
        background: rgb(245 245 245);
    }
    .MapContainer,
    .contactus-form-container {
        width: 100%;
    }
}

@media (max-width: 565px) {
    .whyUs-container {
        padding: 24px;
    }
    .whyUsCard,
    .contactus-main-container {
        padding: 16px;
    }
    .whyUsCard {
        border-radius: 20px;
    }
}

@media (max-width: 565px) {
    .whyUs-container {
        padding: 24px 16px;
    }
}
