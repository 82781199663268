.abhinandan-lodha-page-container {
    background: white;
    padding: 30px 40px 50px 40px;
    margin: auto;
}

.lodha-detail-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    margin-top: 35px;
}

.about-summary-container,
.slider-container {
    width: 50%;
}

.about-summary-container {
    padding: 24px;
    text-align: start;
}

.about-summary-container p {
    font-size: 16px;
    color: rgb(9 9 9);
    letter-spacing: 0.15px;
    line-height: 26px;
    padding-left: 20px;
}

.about-summary-container p::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: var(--dark-green);
    border-radius: 10px;
    margin-left: -16px;
    margin-top: 8px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.imageBorderClip-Path-box {
    width: 100%;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #dbefff;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    animation: 1.5s fadeInRight;
    animation-delay: 3s;
}

@keyframes fadeInRight {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.slider-container img {
    width: 97%;
    /* clip-path: polygon(10% 0, 100% 0%, 89% 100%, 0% 100%); */
    height: 275px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    animation: 1.5s fadeInLeft;
    animation-delay: 3s;
}

@keyframes fadeInLeft {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .lodha-detail-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .about-summary-container,
    .slider-container {
        width: 100%;
    }

    .lodha-detail-container {
        margin-top: 30px;
    }
}
