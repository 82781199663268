.heading_title>h3 {
    font-size: 26px;
    font-weight: 600;
    color: var(--green);
    width: 100%;
    margin: 0;
    font-family: "Poppins";
    padding-bottom: 20px;
}

.termsMainContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 40px 0;
}

.description>p {
    padding-bottom: 20px;
    font-size: 16px;
    color: black;
    margin: 0;
    text-wrap: wrap;
    width: 100%;
    font-family: "Poppins";
    font-weight: 500;
}

.bulletPoint {
    padding-bottom: 10px;
    font-size: 16px;
    color: black;
    margin: 0;
    text-wrap: wrap;
    width: 100%;
    font-family: "Poppins";

}

.subTitle>h5 {
    font-size: 22px;
    font-weight: 600;
    color: var(--green);
    text-wrap: wrap;
    width: 100%;
    margin: 0;
    font-family: "Poppins";
    padding-bottom: 20px;
}